<template>
    <header id="header">
        <div class="container">

            <!-- ._inner -->
            <div class="_inner">

                <!-- #site-logo -->
                <div id="site-logo" class="part">
                    <a href="/" target="_self" title="WebPartner">
                        <img v-bind:alt="$gettext('Staňte sa webhostingovým resellerom s programom Webpartner')" src="assets/img/webhosting-reseller-webpartner.svg">
                    </a>
                </div>
                <!-- /#site-logo -->

                <!-- #site-nav -->
                <div id="site-nav" class="part">
                    <nav>
                        <ul>
                            <router-link to="/"  tag="li" exact-active-class="current" exact><a onclick="$('.hamburger').toggleClass('is-active'); $('#site-nav').toggle();" v-translate>Úvod</a></router-link>
                            <router-link :to="{name: 'how-it-works'}"  tag="li" exact-active-class="current" exact><a onclick="$('.hamburger').toggleClass('is-active'); $('#site-nav').toggle();" v-translate>Ako to funguje</a></router-link>
                            <router-link :to="{name: 'discounts'}"  tag="li" exact-active-class="current" exact><a onclick="$('.hamburger').toggleClass('is-active'); $('#site-nav').toggle();" v-translate>Partnerské zľavy</a></router-link>
                            <router-link :to="{name: 'services'}"  tag="li" exact-active-class="current" exact><a onclick="$('.hamburger').toggleClass('is-active'); $('#site-nav').toggle();" v-translate>Dostupné služby</a></router-link>
                            <router-link :to="{name: 'contact'}"  tag="li" exact-active-class="current" exact><a onclick="$('.hamburger').toggleClass('is-active'); $('#site-nav').toggle();" v-translate>Kontakt</a></router-link>
                            <li><a class="login" href="https://moj.webpartner.sk/login" target="_self"><i class="icon icofont-user-alt-3"></i> <span v-translate>Prihlásenie</span></a></li>
                        </ul>
                    </nav>
                </div>
                <!-- /#site-nav -->

                <button class="hamburger hamburger--spin" type="button">
                          <span class="hamburger-box">
                              <span class="hamburger-inner"></span>
                          </span>
                </button>

            </div>
            <!-- /._inner -->

        </div>
    </header>
</template>

<script>
    export default {
        name: "MainHeader"
    }
</script>