<template>
  <footer id="footer" ref="footerElement">
    <div class="container">
      <div class="footer-logo media">
        <div class="media-body media-middle">Powered by</div>
        <div class="media-right media-middle">
          <img src="assets/img/WEBGLOBE_logo.svg" alt="WebPartner" />
        </div>
      </div>

      <p>
        Webglobe, a.s.<br />
        Stará Prievozská 1349/2<br />
        821 09 Bratislava<br />
        <small>IČO: 52486567 IČ DPH/DIČ: SK2121061899</small>
      </p>

      <div class="site-social">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/WebglobeYegon/"
              target="_blank"
              title="Facebook"
              ><i class="icon icofont-facebook"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/webglobe-sro"
              target="_blank"
              title="LinkedIn"
              ><i class="icon icofont-linkedin"></i
            ></a>
          </li>
          <li>
            <a
              href="https://twitter.com/WebglobeYegon"
              target="_blank"
              title="Twitter"
              ><i class="icon icofont-twitter"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
  <!-- /#footer -->
</template>

<script>
export default {
  name: "MainFooter",
};
</script>