const routes = [
    {
      path: '/',
      name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/how-it-works',
        name: 'how-it-works',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "how-it-works" */ '../views/How_it_works.vue')
    },
    {
        path: '/discounts-for-partners',
        name: 'discounts',
        component: () => import(/* webpackChunkName: "discounts" */ '../views/Discounts.vue')
    },
    {
        path: '/services',
        name: 'services',
        component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "contacts" */ '../views/Contact.vue')
    },
    {
        path: '/registration',
        name: 'registration',
        component: () => import(/* webpackChunkName: "registration" */ '../views/Registration.vue')
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
    },
    {
        path: '/terms_privacy',
        name: 'terms_privacy',
        component: () => import(/* webpackChunkName: "terms_privacy" */ '../views/Terms_privacy.vue')
    },
    {
        path: '/message-sent',
        name: 'message-sent',
        component: () => import(/* webpackChunkName: "message_sent" */ '../views/Message_sent.vue')
    },
    {
        path: '/registration-sent',
        name: 'registration-sent',
        component: () => import(/* webpackChunkName: "registration_sent" */ '../views/Registration_sent.vue')
    },
    {
        path: '/registration-confirmed/:key/:user',
        name: 'registration-confirmedx',
        component: () => import(/* webpackChunkName: "registration_confirmedx" */ '../views/Registration_confirmed.vue')
    },
    {
        path: '/registration-confirmed',
        name: 'registration-confirmed',
        component: () => import(/* webpackChunkName: "registration_confirmed" */ '../views/Registration_confirmed.vue')
    },
    {
        path: '/cookies',
        name: 'cookies-terms',
        component: () => import(/* webpackChunkName: "cookies_terms" */ '../views/Cookies_terms.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
    },
    {
        path: '*',
        name: '404x',
        component: () => import(/* webpackChunkName: "404x" */ '../views/404.vue')
    }
];

export default routes