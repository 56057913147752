import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import routes_en from './routes/en_GB'
import routes_sk from './routes/sk_SK'

Vue.use(Router)
Vue.use(Meta)



const routes_all = {
    en_GB: routes_en,
    sk_SK: routes_sk
};
var lang_var = 'sk_SK'
if (process.env.VUE_APP_LANG !== undefined) {
    lang_var = process.env.VUE_APP_LANG
}

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes_all[lang_var],
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    }

})