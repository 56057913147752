<template>
    <div id="cookies" v-if="not_accepted">
        <div class="container">
            <div class="-inner">
                <div class="_part">
                    <p><span v-translate>Vaše súkromie si ceníme. Aj preto vám poskytujeme detailné informácie o tom,</span>&nbsp;<router-link :to="{name: 'cookies-terms'}" v-translate>ako využívame súbory cookies</router-link>&nbsp;<span v-translate> a iné technológie zabezpečujúce správne fungovanie a rozvoj nášho webu. Pre viac informácií si prečítajte naše</span>&nbsp;<router-link :to="{name: 'terms_privacy'}" v-translate>Informácie o spracúvaní osobných údajov</router-link>.</p>
                </div>
                <div class="_part">
                    <button v-on:click="accept()" class="bttn -orange -outline -small" v-translate>Súhlasím</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Cookies',
    data() {
        return {
            not_accepted: true
        }
    },
    created() {
        this.not_accepted = true;
        if (this.$cookies.get('cookies_accepted') == 'true') {
            this.not_accepted = false;
        }
    },
    methods: {
      accept() {
          this.not_accepted = false;
          this.$cookies.config('30d');
          this.$cookies.set('cookies_accepted', 'true');

      }
    }
}
</script>