import Vue from 'vue'
import App from './App.vue'
import router from './router'
import GetTextPlugin from 'vue-gettext'
import translations from './translations.json'
import VeeValidate from 'vee-validate'
import vSelect from 'vue-select'
import VueCookies from 'vue-cookies'
import VueGtm from 'vue-gtm'

Vue.config.productionTip = false

const $ = require('jquery')
window.jQuery = window.$ = $

var lang_var = 'sk_SK'
if (process.env.VUE_APP_LANG !== undefined) {
    lang_var = process.env.VUE_APP_LANG
}

Vue.use(GetTextPlugin, {
    availableLanguages: {
        en_GB: 'English',
        sk_SK: 'Slovenčina',
    },
    defaultLanguage: lang_var,
    autoAddKeyAttributes: 'true',
    languageVmMixin: {
        computed: {
            currentKebabCase: function () {
                return this.current.toLowerCase().replace('_', '-')
            },
        },
    },
    translations: translations,
})

Vue.use(VeeValidate);
Vue.component('v-select', vSelect);
Vue.use(VueCookies)

VueCookies.config('30d');

router.afterEach(() => {
    Vue.nextTick(() => {
        var WEBPARTNER = window.WEBPARTNER;
        WEBPARTNER.init();
    })
});

Vue.use(VueGtm, {
    id: 'GTM-PWJGKT', // Your GTM ID
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: true
});


new Vue({
    router,
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app');